import * as React from 'react';
// assets
import {
  AppstoreAddOutlined,
  AntDesignOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  FontSizeOutlined,
  LoadingOutlined,
  CalculatorOutlined
} from '@ant-design/icons';

import { Icon } from '@iconify/react';

const sp=()=>{return <Icon icon="game-icons:spinning-sword" />}
const drv =()=>{return  <Icon icon="fluent:drive-train-24-filled" />}

// icons
const icons = {
  FontSizeOutlined,
  BgColorsOutlined,
  BarcodeOutlined,
  AntDesignOutlined,
  LoadingOutlined,
  AppstoreAddOutlined,
  CalculatorOutlined
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const utilities = {
  id: 'utilities',
  title: 'Утилиты',
  type: 'group',
  children: [
    {
      id: 'util-drv',
      title: 'Шасси',
      type: 'item',
      url: '/calc/drv',
      icon: drv
    },
    {
      id: 'util-spinners',
      title: 'Спиннеры',
      type: 'item',
      url: '/calc/spinners',
      icon: sp
    },
    {
      id: 'power',
      title: 'Движение',
      type: 'item',
      url: '/calc/power',
      icon: icons.CalculatorOutlined,
      breadcrumbs: false
    },
  ]
};

export default utilities;
