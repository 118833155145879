import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

function round(v,digits){
  let r=Math.pow(10,digits)
  return Math.round(v*r)/r
}

function Drive() {
  const [batv, setBatv]=React.useState(4.2*4)

  const [motortype, setmotortype]=React.useState(2)
  const [kv, setKv]=React.useState(900)
  const [motr, setMotr]=React.useState(18)
  const [brushedfreerpm, setbrushedfreerpm]=React.useState(20000)
  const [brushedtorque, setbrushedtorque]=React.useState(1.5)

  const [botclass, setBotclass]=React.useState(1.5)
  // const [ringsize, setRingsize]=React.useState(2.5)
  const [botmass, setBotmass]=React.useState(1.5)
  const [botmag, setBotmag]=React.useState(0)
  const [motnum, setMotnum]=React.useState(1)
  const [wheelreduction, setWheelreduction]=React.useState(38)
  const [wheeldia, setWheeldia]=React.useState(64)
  const [wheelgrip, setWheelgrip]=React.useState(0.9)
  const [motormaxpower, setMotormaxpower]=React.useState(70)

  let kt = 60 / (2*Math.PI*kv)
  let Rcell = 10
	let totOhms = (batv / 4.2) * Rcell + 1 * motr; // calculate stall from Ri, Rcell, and opvoltage -- New MJ
	let stall = 1000 * 0.8 * batv / totOhms; // power correction for reduced low-speed torque: 0.8 -- New MJ

	
	let totMaxForce = wheelgrip * ( +botmag + +botmass); //magnet downforce addin from totGrip
	
//	let motorMoment = totMaxForce*(botmass / (motnum*2))*(wheeldia/2) / wheelreduction;
  let motorMoment = totMaxForce * (wheeldia/10/2) / wheelreduction / (motnum*2); // kg/cm
  let motorMomentNm = motorMoment * 9.8 /100; // N/m
  let wheelrpm    = (batv*kv)/wheelreduction;
	let topSpeed    = (wheelrpm*Math.PI*wheeldia/1000)/60; // m/s
	let motorAmps   =  motorMomentNm / kt;
	let totalAmps   = motnum*2*motorAmps;
	let imax        = stall;
	let ah3min      = totalAmps*1000*(motormaxpower/100)*(3/60);
	let ah5min      = totalAmps*1000*(motormaxpower/100)*(5/60);
	
	// if (motorAmps > imax) {
	// 	pageElement.innerHTML= outString.substr(0,4)+ " >&nbsp;stall ";
	// 	pageElement.style.color="#FF4488";
	// } else {
	//    	pageElement.innerHTML= outString.substr(0,4)+ " Amps";
	// 	pageElement.style.color="#88DD66";
	// }

  // let ringdiagonal = Math.sqrt(ringsize*ringsize*2)
  let botmaxacc = totMaxForce * 9.8 / botmass
  // let botmaxspeedtime = Math.round(Math.sqrt(2*ringdiagonal / botmaxacc)*100)/100
  // let botmaxspeed = Math.sqrt(2*ringdiagonal*botmaxacc)


  return (
    <div>
      <div style={{background:'white',maxWidth:'80em',margin:'auto'}}>
      <h2>Движение, расчет параметров</h2>
      <div>
      <table style={{width:'100%'}}><tbody>
      <tr><td style={{textAlign:'end'}}>Батарея</td>
      <td><TextField type='number' label="Напряжение" sx={{ m: 1, width: '22ch' }} inputProps={{min: 4.2, step:4.2, max:4.2*14}}
        InputProps={{endAdornment: <InputAdornment position="end">В</InputAdornment>}} value={batv} onChange={(event) => {setBatv(event.target.value);}}/>
        </td><td style={{fontSize:'80%'}}>Расчетное внутреннее <br/>сопротивление батареи <b>{round((batv / 4.2) * Rcell,0)}</b> mΩ</td></tr>

        <tr><td style={{textAlign:'end'}}>Мотор</td>
        <td><Select size='small'
        labelId="demo-select-small-label"
        id="demo-select-small"
    value={motortype}
    onChange={(event) => {setmotortype(event.target.value);}}
  > <MenuItem value={1}>Щеточный</MenuItem>
  <MenuItem value={2}>Бесщеточный (BLDC)</MenuItem>
</Select></td>
        <td>{
        (motortype===1)?
            <TextField size='small'  type='number' label="Обороты без нагрузки (free rpm)" sx={{ m: 1, width: '26ch' }} inputProps={{min: 4.2, step:4.2, max:4.2*14}}
            InputProps={{endAdornment: <InputAdornment position="end">об/мин (rpm)</InputAdornment>}} value={brushedfreerpm} onChange={(event) => {setbrushedfreerpm(event.target.value);}}/>
            :
          <TextField type='number' label="Коэффициент Kv" sx={{ m: 1, width: '22ch' }} inputProps={{min: 100, step:100,}}
        InputProps={{endAdornment: <InputAdornment position="end">Rpm/V</InputAdornment>}} value={kv} onChange={(event) => {setKv(event.target.value);}}/>
          }
        </td>
        <td>
        {
        (motortype===1)?
        <TextField size='small'  type='number' label="Максимальный момент" sx={{ m: 1, width: '22ch' }} inputProps={{min: 100, step:100,}}
        InputProps={{endAdornment: <InputAdornment position="end">Н/м</InputAdornment>}} value={brushedtorque} onChange={(event) => {setbrushedtorque(event.target.value);}}/>
        :
        <TextField size='small' type='number' label="Сопротивление обмоток" sx={{ m: 1, width: '22ch' }} inputProps={{min: 1, step:1,}}
        InputProps={{endAdornment: <InputAdornment position="end">mΩ</InputAdornment>}} value={motr} onChange={(event) => {setMotr(event.target.value);}}/>
        }
        </td>
        <td style={{fontSize:'80%'}}>Теоретическая максимальная сокрость<br/> вращения <b>{round(batv*kv,0)}</b> об/мин (rpm)</td>
        </tr>

        <tr><td style={{textAlign:'end'}}>Робот</td>
        <td><div><Select size='small' labelId="demo-select-small-label" id="demo-select-small" value={botclass} onChange={(event) => {setBotclass(event.target.value); setBotmass(event.target.value)}}>
          <MenuItem value={1.5}>1.5кг</MenuItem>
          <MenuItem value={6}>6кг</MenuItem>
          <MenuItem value={15}>15кг</MenuItem>
          <MenuItem value={110}>110кг</MenuItem>
        </Select></div>
        </td>
        <td>
      <TextField type='number' id="outlined-required" label="Масса" sx={{ m: 1, width: '22ch' }}  inputProps={{min: 0, step:0.1, max:botclass}}
        InputProps={{endAdornment: <InputAdornment position="end">кг</InputAdornment>,}} value={botmass} onChange={(event) => {setBotmass(event.target.value);}}/>
        </td>
        <td>
      <TextField type='number' id="outlined-required" label="Сила магнитов" sx={{ m: 1, width: '22ch' }} inputProps={{min: 0, step:0.1,}}
        InputProps={{endAdornment: <InputAdornment position="end">кгс</InputAdornment>,}} value={botmag} onChange={(event) => {setBotmag(event.target.value);}}/>
        </td><td style={{fontSize:'80%'}}>Теоретическая максимальная сила<br/> толкающая робота <b>{round(totMaxForce,1)}</b> кгс</td></tr>

        <tr><td style={{textAlign:'end'}}>Шасси</td>
        <td><TextField type='number' id="outlined-required" label="Моторов с каждой стороны" sx={{ m: 1, width: '22ch' }} 
        value={motnum} onChange={(event) => {setMotnum(event.target.value);}}/>
        </td>
        <td><TextField type='number' id="outlined-required" label="Коэффициент редукции" sx={{ m: 1, width: '22ch' }} 
        value={wheelreduction} onChange={(event) => {setWheelreduction(event.target.value);}}/>
        </td>
        <td><TextField type='number' id="outlined-required" label="диаметр колес" sx={{ m: 1, width: '22ch' }} 
        InputProps={{endAdornment: <InputAdornment position="end">мм</InputAdornment>,}} value={wheeldia} onChange={(event) => {setWheeldia(event.target.value);}}/>
        </td>
        <td><TextField type='number' id="outlined-required" label="Коэффициент сцепления колес" sx={{ m: 1, width: '22ch' }} inputProps={{min: 0, step:0.1, max:1}}
        value={wheelgrip} onChange={(event) => {setWheelgrip(event.target.value);}}/>
        </td>
        <td><TextField type='number' id="outlined-required" label="Процент максимальной тяги моторов" sx={{ m: 1, width: '22ch' }} 
        InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>,}} value={motormaxpower} onChange={(event) => {setMotormaxpower(event.target.value);}}/>
        </td></tr>
        </tbody>
        </table>
      <Box sx={{ width: '100%' }}>
        <table style={{width:'100%'}}><tbody>
          <tr><td style={{textAlign:'end'}} >Коэффициент момента мотора Kt</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(kt,4)}</b> Н/м/А</td></tr>
          {/* <tr><td style={{textAlign:'end'}} >Момент мотора для максимальной силы на колесе</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(motorMoment,2)}</b> кг/см</td></tr> */}
          <tr><td style={{textAlign:'end'}} >Момент каждого мотора для максимальной силы на колесе</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(motorMomentNm,2)}</b> Н/м (<b>{round(motorMoment,2)}</b> кг/см)</td></tr>
          <tr><td style={{textAlign:'end'}} >Максимальное ускорение</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(botmaxacc,1)}</b> м/с<sup>2</sup> (<b>{round(botmaxacc/9.8,1)}</b> g)</td></tr>
          {/* <tr><td style={{textAlign:'end'}} >Максимальное ускорение</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(botmaxacc/9.8,1)}</b> g</td></tr> */}
          <tr><td style={{textAlign:'end'}} >Суммарный ток всех моторов шасси для обеспечения максимального ускорения</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(totalAmps,1)}</b> А</td></tr>
          <tr><td style={{textAlign:'end'}} >Теоретическая максимальная сокрость вращения колес</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(wheelrpm,2)}</b> об/мин (rpm)</td></tr>
          <tr><td style={{textAlign:'end',width:'50%'}} >Теоретическая максимальная сокрость робота</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(topSpeed,2)}</b> м/с (<b>{round(topSpeed*3600/1000,1)}</b> км/ч)</td></tr>
          <tr><td style={{textAlign:'end'}} >Максимальный возможный ток мотора (при остановке)</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(imax,1)}</b> А</td></tr>
          <tr><td style={{textAlign:'end'}} >Максимальный момент мотора (при остановке)</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(imax*kt,1)}</b> Н/м</td></tr>
          <tr><td style={{textAlign:'end'}} >мА/ч необходимо на 3 минуты</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(ah3min,1)}</b> мА/ч</td></tr>
          <tr><td style={{textAlign:'end'}} >мА/ч необходимо на 5 минут</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(ah5min,1)}</b> мА/ч</td></tr>
        </tbody></table>
      </Box>
    </div>
  </div>
  </div>
  );
}
 
export default Drive;
  