import * as React from 'react';
import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';
import Power from '../components/power';
import Drive from '../components/drive';
import BLDCConsts from '../pages/bldcconsts';
import Rules from 'pages/Rules';
import MD from 'pages/MD';
import Rules2 from 'pages/Rules2';
import Spinners from 'components/spinners';

// render - dashboard
// const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard')));

// render - sample page
// const SamplePage = Loadable(lazy(() => import('../pages/extra-pages/SamplePage')));

// render - utilities
// const Typography = Loadable(lazy(() => import('../pages/components-overview/Typography')));
// const Color = Loadable(lazy(() => import('../pages/components-overview/Color')));
// const Shadow = Loadable(lazy(() => import('../pages/components-overview/Shadow')));
// const AntIcons = Loadable(lazy(() => import('../pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: (<div><MD markdown='rules'/></div>)
    },
    // {
    //   path: 'dashboard',
    //   children: [
    //     {
    //       path: 'default',
    //       element: <DashboardDefault />
    //     }
    //   ]
    // },
    {
      path: 'md/:markdown',
        element: <MD/>
    },
    {
      path: 'bldcconsts',
      element: <BLDCConsts />
    },
    {
      path: 'calc/drv',
      element: <Drive />
    },
    {
      path: 'calc/spinners',
      element: <Spinners />
    },
    {
      path: 'calc/power',
      element: <Power/>
    },
    // {
    //   path: 'typography',
    //   element: <Typography />
    // },
    // {
    //   path: 'icons/ant',
    //   element: <AntIcons />
    // }
  ]
};

export default MainRoutes;
