import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { Icon } from '@iconify/react';

import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Fade, IconButton, Paper, Popper, Typography } from '../../node_modules/@mui/material/index';

function round(v,digits){
//    if (digits<=0) {
        let r=Math.pow(10,digits)
        return Math.round(v*r)/r
//    }
//    return (+v).toPrecision(digits);
}

function Spinners() {

    const [diskdens, setDiskdens]=React.useState(7800)
    const [diskr, setDiskr]=React.useState(100)
    const [diskt, setDiskt]=React.useState(12)

    const [bardens, setbardens]=React.useState(7800)
    const [barw, setbarw]=React.useState(0)
    const [bart, setbart]=React.useState(0)
    const [barl, setbarl]=React.useState(0)

    const [tubedens, settubedens]=React.useState(7800)
    const [tuber1, settuber1]=React.useState(0)
    const [tuber2, settuber2]=React.useState(0)
    const [tubel, settubel]=React.useState(0)

    const [impactsmass, setimpactsmass]=React.useState(0)
    const [impactsr, setimpactsr]=React.useState(0)

    const [knownMOI,setknownMOI]=React.useState(false)
    const [MOI,setMOI]=React.useState(0.1)

    const [batv, setBatv]=React.useState(14.8)//4.2*4)
    const [batnum, setBatnum]=React.useState(1)
    const [motnum, setMotnum]=React.useState(1)
  const [kv, setKv]=React.useState(1200)
  const [motr, setMotr]=React.useState(32)
  const [motortype, setmotortype]=React.useState(2)
  const [botmass, setBotmass]=React.useState(1.5)
  const [reduction, setWheelreduction]=React.useState(2)

  const [freerpm, setfreerpm]=React.useState(20000)
  const [brushedtorque, setbrushedtorque]=React.useState(1.5)

  const [sec_time, setsec_time]=React.useState(2)

  let kt = 60 / (2*Math.PI*kv)
  let rpm = (batv*kv)/reduction;
	
  let M9 = 0.000000001 ;
  let M6 = 0.000001 ;
   // brushless conversion to stall torque and RPM
   let motspeedrpm = (batv * kv);
   if (motortype==1) motspeedrpm = freerpm

   //let ri =  (batv / 4.2) * 5.6 / batnum 	// Estimates battery ri for 60C battery 5100 mAh 6S (306 A)
   let ri =  (batv / 4.2) * 3.9 / batnum	// Estimates battery ri for 120C battery 1300 mAh 4S (156 A)
//   let Rcell = 10
//   let totOhms = (batv / 4.2) * Rcell + 1 * motr; // calculate stall from Ri, Rcell, and opvoltage -- New MJ
   let totOhms = ri + 1 * motr / motnum; // calculate stall from Ri, Rcell, and opvoltage -- New MJ
   console.log( totOhms, ri, motr);
   let totalAmps   = 1000 * batv / totOhms / motnum
   console.log( totalAmps); 
   let mottorque = kt * totalAmps 	// Torque in Nm
   if (motortype==1) mottorque = brushedtorque

   // mass calculations
    let diskmass = Math.PI * diskdens * diskt * diskr * diskr * M9 ;
    let barmass = bardens * barl * barw * bart * M9 ;
    let tubemass = Math.PI * tubedens * tubel * (( tuber2 * tuber2 ) - ( tuber1 * tuber1 )) * M9 ;
    let totalmass = diskmass + barmass + tubemass + +impactsmass;

   // mass moment calculations
    let diskMOI = 0.5 * Math.pow( diskr, 2 ) * diskmass * M6 ; 
    let barMOI = 1/12 * barmass * ( Math.pow( barl, 2 ) + Math.pow( barw, 2 )) * M6 ; 
    let tubeMOI = 0.5 * tubemass * ( Math.pow( tuber2, 2 ) + Math.pow( tuber1, 2 )) * M6 ; 
    let impactMOI = Math.pow( impactsr, 2 ) * impactsmass * M6 ;
    let momentinertia = diskMOI + barMOI + tubeMOI + impactMOI;
    if (knownMOI)
        momentinertia = MOI

   // rotational speed calculations
    let gear = 1 / reduction ;
    let speedrpm = motspeedrpm * gear ; if (momentinertia == 0) {speedrpm = 0} ;
    let speedrad = ( speedrpm * Math.PI ) / 30 ;
    let real3 = speedrpm * 0.95 ;
    let real2 = speedrpm * 0.8647 ;
    let real1 = speedrpm * 0.6325 ;

   // torque calculations
    let weapontorque = mottorque * motnum / gear ;

   // tip speed calculations
    let barrad = Math.sqrt( Math.pow( 0.5 * barl, 2 ) +  Math.pow( 0.5 * barw, 2 )) ;
    let speedms = Math.max( barrad, diskr, tuber2, impactsr ) * 2 * Math.PI * ( speedrpm / 60 ) * 0.001 ;

   // time calculations
    let time1 = ( momentinertia * speedrad ) / weapontorque ; //document.getElementById("t1").innerHTML = time.toPrecision(3) ;	
    let time2 = time1 * 2 ; //document.getElementById("t2").innerHTML = time2.toPrecision(3) ;
    let time3 = time1 * 3 ; //document.getElementById("t3").innerHTML = time3.toPrecision(3) ;

   // energy calculations
    let totalenergy = 0.5 * momentinertia * Math.pow( speedrad, 2 )
    let trim = 0 ; 
    let ke3 = totalenergy * 0.9025 ; if (ke3 < 100) {let trim = 1 ;}
    let ke2 = totalenergy * 0.7477 ; if (ke2 < 100) {let trim = 1 ;}
    let ke1 = totalenergy * 0.4001 ; if (ke1 < 100) {let trim = 1 ;}

    // @ specific time calculations
    let sec_speed = (( 1 - Math.exp(-sec_time / time1)) * rpm ) ;
    let sec_joules = ( 0.5 * momentinertia * Math.pow( sec_speed * 0.105, 2 )) ;
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
      };

  return (
    <div>
      <div style={{background:'white',maxWidth:'80em',margin:'auto'}}>
        <table><tbody>
            <tr>
            <td><img src="/img/disk.png" alttext="Kt"/></td>
            <td><img src="/img/bar.png" alttext="Kt"/></td>
            <td><img src="/img/tube.png" alttext="Kt"/></td>
            <td><img src="/img/impactors.png" alttext="Kt"/></td>
            </tr>
            <tr><td>диск</td><td>Брусок</td><td>труба</td><td>ударные элементы</td></tr>
        </tbody>
        </table>
        
        <p>Параметры спинера</p>
        <Popper
        // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
        sx={{ zIndex: 1200 }}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper style={{padding:'10px'}}>
                Плотность
              <pre>Сталь        7800</pre>
              <pre>Титан        4500</pre>
              <pre>Алюминий     2760</pre>
              <pre>Магний       1770</pre>
              <pre>Углепластик  1600</pre>
              <pre>Лексан       1200</pre>
            </Paper>
          </Fade>
        )}
      </Popper>
      <table style={{width:'100%'}}><tbody>
      <tr>
        <td>Диск</td>
        <td><TextField disabled={knownMOI} size='small'  type='number' label="Плотность" sx={{ m: 1, width: '22ch' }} inputProps={{min: 1, step:100, max:10000}}
        InputProps={{endAdornment: <InputAdornment position="end">Кг/м<sup>3</sup></InputAdornment>}} value={diskdens} onChange={(event) => {setDiskdens(event.target.value);}}/>
        <IconButton onClick={handleClick('right')}><Icon style={{paddingTop:'0.2em'}} icon="la:question-circle"></Icon></IconButton>
        </td>
        <td><TextField disabled={knownMOI} size='small'  type='number' label="Радиус" sx={{ m: 1, width: '22ch' }} 
        InputProps={{endAdornment: <InputAdornment position="end">мм</InputAdornment>}} value={diskr} onChange={(event) => {setDiskr(event.target.value);}}/>
        </td>
        <td><TextField disabled={knownMOI} size='small'  type='number' label="Толщина" sx={{ m: 1, width: '22ch' }} 
        InputProps={{endAdornment: <InputAdornment position="end">мм</InputAdornment>}} value={diskt} onChange={(event) => {setDiskt(event.target.value);}}/>
        </td>
        <td>
        </td>
        <td style={{fontSize:'80%'}}>
            масса {round(diskmass,2)} кг<br/> момент {round(diskMOI,2)} кг/м<sup>2</sup>
        </td>
        </tr>

        <tr>
        <td>Брусок</td>
        <td><TextField disabled={knownMOI} size='small'  type='number' label="Плотность" sx={{ m: 1, width: '22ch' }} inputProps={{min: 1, step:100, max:10000}}
        InputProps={{endAdornment: <InputAdornment position="end">Кг/м<sup>3</sup></InputAdornment>}} value={bardens} onChange={(event) => {setbardens(event.target.value);}}/>
        <IconButton onClick={handleClick('right')}><Icon style={{paddingTop:'0.2em'}} icon="la:question-circle"></Icon></IconButton>
        </td>
        <td><TextField disabled={knownMOI} size='small'  type='number' label="Ширина" sx={{ m: 1, width: '22ch' }} 
        InputProps={{endAdornment: <InputAdornment position="end">мм</InputAdornment>}} value={barw} onChange={(event) => {setbarw(event.target.value);}}/>
        </td>
        <td><TextField disabled={knownMOI} size='small'  type='number' label="Толщина" sx={{ m: 1, width: '22ch' }} 
        InputProps={{endAdornment: <InputAdornment position="end">мм</InputAdornment>}} value={bart} onChange={(event) => {setbart(event.target.value);}}/>
        </td>
        <td><TextField disabled={knownMOI} size='small'  type='number' label="Длина" sx={{ m: 1, width: '22ch' }} 
        InputProps={{endAdornment: <InputAdornment position="end">мм</InputAdornment>}} value={barl} onChange={(event) => {setbarl(event.target.value);}}/>
        </td>
        <td style={{fontSize:'80%'}}>
            масса {round(barmass,2)} кг<br/> момент {round(barMOI,2)} кг/м<sup>2</sup>
        </td>
        </tr>
        
        <tr>
        <td>Труба</td>
        <td><TextField disabled={knownMOI} size='small'  type='number' label="Плотность" sx={{ m: 1, width: '22ch' }} inputProps={{min: 1, step:100, max:10000}}
        InputProps={{endAdornment: <InputAdornment position="end">Кг/м<sup>3</sup></InputAdornment>}} value={tubedens} onChange={(event) => {settubedens(event.target.value);}}/>
        <IconButton onClick={handleClick('right')}><Icon style={{paddingTop:'0.2em'}} icon="la:question-circle"></Icon></IconButton>
        </td>
        <td><TextField disabled={knownMOI} size='small'  type='number' label="внутренний радиус" sx={{ m: 1, width: '22ch' }} 
        InputProps={{endAdornment: <InputAdornment position="end">мм</InputAdornment>}} value={tuber1} onChange={(event) => {settuber1(event.target.value);}}/>
        </td>
        <td><TextField disabled={knownMOI} size='small'  type='number' label="внешний радиус" sx={{ m: 1, width: '22ch' }} 
        InputProps={{endAdornment: <InputAdornment position="end">мм</InputAdornment>}} value={tuber2} onChange={(event) => {settuber2(event.target.value);}}/>
        </td>
        <td><TextField disabled={knownMOI} size='small'  type='number' label="Длина" sx={{ m: 1, width: '22ch' }} 
        InputProps={{endAdornment: <InputAdornment position="end">мм</InputAdornment>}} value={tubel} onChange={(event) => {settubel(event.target.value);}}/>
        </td>
        <td style={{fontSize:'80%'}}>
            масса {round(tubemass,2)} кг<br/> момент {round(tubeMOI,2)} кг/м<sup>2</sup>
        </td>
        </tr>

        <tr>
        <td>Ударные<br/> элементы</td>
        <td><TextField disabled={knownMOI} size='small'  type='number' label="масса" sx={{ m: 1, width: '22ch' }} inputProps={{min: 0, step:0.1, max:110}}
        InputProps={{endAdornment: <InputAdornment position="end">Кг</InputAdornment>}} value={impactsmass} onChange={(event) => {setimpactsmass(event.target.value);}}/>
        </td>
        <td><TextField disabled={knownMOI} size='small'  type='number' label="радиус" sx={{ m: 1, width: '22ch' }} 
        InputProps={{endAdornment: <InputAdornment position="end">мм</InputAdornment>}} value={impactsr} onChange={(event) => {setimpactsr(event.target.value);}}/>
        </td>
        <td colSpan={2}>
        </td>
        <td style={{fontSize:'80%'}}>
            момент {round(impactMOI,2)} кг/м<sup>2</sup>
        </td>
        </tr>
        <tr><td colSpan={2}>известный момент инерции
            <Checkbox checked={knownMOI} onChange={(event) => {setknownMOI(event.target.checked);}}
            inputProps={{ 'aria-label': 'controlled' }} />
            </td>
            <td>
            {knownMOI?<TextField size='small'  type='number' label="момент инерции" sx={{ m: 1, width: '22ch' }} 
        InputProps={{endAdornment: <InputAdornment position="end">кг/м<sup>2</sup></InputAdornment>}} value={MOI} onChange={(event) => {setMOI(event.target.value);}}/>
            :<></>}
            </td>
            </tr>
        </tbody>
        </table>
        
        <p>Параметры привода спинера</p>
        {/* <p>
        </p> */}
      <table style={{width:'100%'}}><tbody>

<tr><td>АКБ</td><td><TextField size='small'  type='number' label="Напряжение" sx={{ m: 1, width: '22ch' }} inputProps={{min: 4.2, step:4.2, max:4.2*14}}
            InputProps={{endAdornment: <InputAdornment position="end">В</InputAdornment>}} value={batv} onChange={(event) => {setBatv(event.target.value);}}/></td>
            <td><TextField size='small' type='number' id="outlined-required" label="Батарей" sx={{ m: 1, width: '10ch' }} 
        value={batnum} onChange={(event) => {setBatnum(event.target.value);}}/>
        </td>
            <td colSpan={3} style={{fontSize:'80%'}}>ячеек {Math.round(batv/4.2-0.495 )}S, <br/>внутреннее сопротивление {round(ri,1)} mΩ</td>
            </tr>
      <tr><td>Мотор</td>
        <td><Select size='small'
        labelId="demo-select-small-label"
        id="demo-select-small"
    value={motortype}
    onChange={(event) => {setmotortype(event.target.value);}}
  > <MenuItem value={1}>Щеточный</MenuItem>
  <MenuItem value={2}>Бесщеточный (BLDC)</MenuItem>
  <MenuItem value={3}>Бессенсорный (BLDC)</MenuItem>
</Select></td>
        <td><TextField size='small' type='number' id="outlined-required" label="Моторов" sx={{ m: 1, width: '10ch' }} 
        value={motnum} onChange={(event) => {setMotnum(event.target.value);}}/>
        </td>
        <td>
            {
            (motortype===1)?
            <TextField size='small'  type='number' label="Обороты без нагрузки (free rpm)" sx={{ m: 1, width: '26ch' }} inputProps={{min: 4.2, step:4.2, max:4.2*14}}
            InputProps={{endAdornment: <InputAdornment position="end">об/мин (rpm)</InputAdornment>}} value={freerpm} onChange={(event) => {setfreerpm(event.target.value);}}/>
            :
            <TextField size='small'  type='number' label="Коэффициент Kv" sx={{ m: 1, width: '22ch' }} inputProps={{min: 100, step:100,}}
        InputProps={{endAdornment: <InputAdornment position="end">Rpm/V</InputAdornment>}} value={kv} onChange={(event) => {setKv(event.target.value);}}/>
            }
        </td>
        <td>
        {
        (motortype===1)?
        <TextField size='small'  type='number' label="Максимальный момент" sx={{ m: 1, width: '22ch' }} inputProps={{min: 100, step:100,}}
        InputProps={{endAdornment: <InputAdornment position="end">Н/м</InputAdornment>}} value={brushedtorque} onChange={(event) => {setbrushedtorque(event.target.value);}}/>
        :
        <TextField size='small' type='number' label="Сопротивление обмоток" sx={{ m: 1, width: '22ch' }} inputProps={{min: 1, step:1,}}
        InputProps={{endAdornment: <InputAdornment position="end">mΩ</InputAdornment>}} value={motr} onChange={(event) => {setMotr(event.target.value);}}/>
        }
        </td>

        <td style={{textAlign:'end'}}>
      <TextField size='small' type='number' id="outlined-required" label="Коэффициент редукции" sx={{ m: 1, width: '22ch' }} 
        value={reduction} onChange={(event) => {setWheelreduction(event.target.value);}}/>
        </td></tr>

        </tbody>
        </table>

      <Box sx={{ width: '100%' }}>
      <table style={{width:'100%'}}><tbody>
        <tr><td>
        <table style={{width:'100%'}}><tbody>
          <tr><td style={{textAlign:'end'}} >масса оружия</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{ knownMOI?'?':round(totalmass,2)}</b> кг</td></tr>
          <tr><td style={{textAlign:'end'}} >Момент инерции</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(momentinertia,5)}</b> кг/м<sup>2</sup></td></tr>
          <tr><td style={{textAlign:'end'}} >скорость оружия</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(speedrpm,-1)}</b> об/мин (rpm)</td></tr>
          <tr><td style={{textAlign:'end'}} >окружная скорость</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(speedms,2)}</b> м/с (<b>{round(speedms*3600/1000,2)}</b> км/ч)</td></tr>
          <tr><td style={{textAlign:'end',width:'50%'}} >Кинектичкая энергия</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(totalenergy,0)}</b> Дж</td></tr>
          <tr><td style={{textAlign:'end'}} >Максимальный ток оружия</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(totalAmps,2)}</b> А {motnum!=1?`(батарея ${round(totalAmps * motnum / batnum,2)} А)`:''}</td></tr>
        </tbody></table>
        </td>
        <td>
            график энергии от времени
            <div style={{position:'relative'}}>
            <img src="/img/spinplot2.png" alt="" />
            <span style={{position:'absolute',left:'10px',top:'15px'}}>{`${round(ke3,-1)} Дж ${round(real3,-1)} rpm`}</span>
            <span style={{position:'absolute',left:'10px',top:'50px'}}>{`${round(ke2,-1)} Дж ${round(real2,-1)} rpm`}</span>
            <span style={{position:'absolute',left:'10px',top:'128px'}}>{`${round(ke1,-1)} Дж ${round(real1,-1)} rpm`}</span>
            <span style={{position:'absolute',left:'144px',bottom:'10px'}}>{`${round(time1,1)}сек`}</span>
            <span style={{position:'absolute',left:'270px',bottom:'10px'}}>{`${round(time2,1)}сек`}</span>
            <span style={{position:'absolute',left:'398px',bottom:'10px'}}>{`${round(time3,1)}сек`}</span>
            </div>
        </td>
        </tr>
        <tr>
            <td colSpan={2} style={{textAlign:'center'}}>
            <span style={{display:'inline-block',paddingTop:'1em'}}>через</span> <TextField size='small' type='number' id="outlined-required" label="время" sx={{ m: 1, width: '22ch' }} 
        InputProps={{endAdornment: <InputAdornment position="end">сек</InputAdornment>}}value={sec_time} onChange={(event) => {setsec_time(event.target.value);}}/> оружие 
        раскрутится до {round(sec_speed,-1)} rpm приобретя энергию {round(sec_joules,-1)} Дж
          

            </td>
        </tr>
        </tbody></table>
      </Box>
      <Accordion>
        <AccordionSummary expandIcon={<Icon icon="line-md:arrow-open-down"/>}>Помощь</AccordionSummary>
        <AccordionDetails>
            <p>Калькулятор выполняет несколько функций, помогающих конструктору спроектировать эффективное вращающееся оружие.</p>
            <p>Калькулятор выдает массу и момент инерции вращающегося оружия, состоящего из труб, дисков и брусков известных размеров и плотности материала, 
                а также момент инерции ударных элементов известной массы и расстояния от оси вращения.</p>
                <p>Вы можете комбинировать элементы, имеющие общую ось вращения, чтобы создавать более сложные формы например:</p>
                <ul>
                    <li>Полнотелый спиннер представляет собой трубу плюс верхний диск</li>
                    <li>Спиннер с полым барабаном представляет собой трубу с двумя торцевыми дисками, (в виде одного диска двойной толщины)</li>
                    <li>Прямоугольный спиннер - это два бруска плюс два ударных элемента, каждая пара представлена в виде одного элемента двойной толщины.</li>
                </ul>
                <p>Если вы знаете момент инерции для сложного или асимметричного оружия, разработанного с помощью CADа, и хотите 
                    использовать только функции калькулятора вращения, введите момент непосредственно и поставьте галочку "известный момент".</p>
                <p>Калькулятор обеспечивает накопление кинетической энергии и приблизительное время раскручивания оружия при питании от конкретного 
                    электродвигателя. 
                    Необходимо ввести характеристики двигателя, обычно доступные для бесщеточных двигателей: 
                    Kv (число оборотов на вольт) и Ri двигателя (внутреннее сопротивление). 
                    Калькулятор также запрашивает выбранное напряжение батареи и коэффициент редукции между двигателем и вращающимся оружием. Для щеточного двигателя - оброты холостого хода и момент при останове мотора.
                    {/* Смотрите также: Спросите Аарона: выбор бесщеточного двигателя */}
                    {/* Что означают эти показатели эффективности оружия? Сколько кинетической энергии достаточно? Как быстро должно раскручиваться ваше оружие? Сумасшедшие высокие обороты - хорошая идея? */}
                </p>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<Icon icon="line-md:arrow-open-down"/>}>Примеры</AccordionSummary>
        <AccordionDetails>
        <h3>Пример калькулятора: Простой брусок</h3>
        <p>спиннер в виде бруска прост и надежен. Его также легко ввести в калькулятор.
        Мы вводим следующие значения в строку "Bar" в разделе "Элементы оружия" калькулятора:</p>
        <pre>Плотность: 7800 [плотность стали]</pre>
        <pre>Ширина: 90 мм</pre>
        <pre>Толщина: 16 мм</pre>
        <pre>Длина: 500 мм</pre>
<p>Калькулятор  показывает, что вес оружия составляет 5,6 килограмма.
При настройках двигателя оружия по умолчанию оружие будет вращаться со скоростью 52 м/с с запасом энергии около 2600 джоулей, достигая 2300 джоулей за 2,6 секунды.
</p>
<h3>Пример калькулятора: Толстый барабан</h3>
<p>Барабан в стиле минотавра представляет собой единую толстостенную трубку с отдельными ударными элементами. Массу ударников необходимо рассчитать отдельно и ввести 
на соответствующем расстоянии от оси вращения.
Мы начинаем с ударных элементов, вводя следующие значения в строку "брусок", чтобы определить вес ударного элемента:
</p>
<pre>Плотность: 7800 [плотность стали]</pre>
<pre>Ширина: 50 мм</pre>
<pre>Толщина: 50 мм</pre>
<pre>Длина: 200 мм</pre>
<p>
Калькулятор  показывает, что брусок весит 3,9 килограмма.
</p>
Далее мы вводим следующие значения в строку "Труба" калькулятора:
<pre>Плотность: 7800 [плотность стали]</pre>
<pre>Внутренний радиус: 25 мм</pre>
<pre>Внешний радиус: 76 мм</pre>
<pre>Длина: 200 мм</pre>
<p>
Калькулятор показывает, что труба весит 25 килограммов.
</p>
<p>
Теперь мы добавим ранее рассчитанную массу бруска в строку "Ударные" калькулятора:
</p>
<pre>масса: 3.9 kg</pre>
<pre>радиус: 102 mm</pre>
<p>
Калькулятор показывает, что общий вес оружия с ударными элементами составляет 29 килограммов.
При настройках двигателя оружия по умолчанию оружие будет вращаться со скоростью 21 м / с с запасом энергии около 2600 джоулей, достигая 2300 джоулей за 2,6 секунды.
</p>
<h3>Пример калькулятора: Рамка</h3>
<p>Прямоугольный спиннер сложно рассчитать, потому что она представляет собой комбинацию из четырех элементов: двух "брусков" и двух длинных "ударников".</p>
<p>Мы начнем с ввода следующих значений в строку "брусок", чтобы определить вес двух ударных элементов:</p>
<p>Ввод в калькулятор двух стальных "ударных элементов" :</p>
<pre>Плотность: 7800 [плотность стали]</pre>
<pre>Ширина: 25,4 мм</pre>
<pre>Толщина: 50,8 мм [двойная толщина = два ударных элемента]</pre>
<pre>Длина: 300 мм </pre>
<p>Калькулятор показывает, что два ударных элемента весят около 3 килограммов.</p>
<p>Затем мы вводим размеры двух брусков в строке "Брусок", оставляя только значения ширины и толщины и укорачивая длину, чтобы она соответствовала брускам:</p>
<pre>Плотность: 7800 [плотность стали]</pre>
<pre>Ширина: 25,4 мм</pre>
<pre>Толщина: 50,8 мм [двойная толщина = два бруска]</pre>
<pre>Длина: 200 мм</pre>
<p>Наконец, мы вводим вес двух ударных элементов и радиус до их центра масс в строку "Ударные элементы" :</p>
<pre>Масса: 3 кг</pre>
<pre>Радиус: 89 мм</pre>
<p>Калькулятор показывает, что общий вес рамки составляет 5 килограммов.</p>
<p>При настройках двигателя оружия по умолчанию оружие будет вращаться со скоростью 21 м/с, затрачивая около 2600 джоулей запасенной энергии, 
достигая 2300 джоулей за 2,6 секунды.</p>
<p>Стоит упомянуть, что оружие такого размера, изготовленное из квадратного бруска толщиной 25мм дюйм, очень скручивается - стоило бы сделать его из более толстого и широкого материала.</p>
        </AccordionDetails>
      </Accordion>
    </div>
  </div>
  );
}
 
export default Spinners;
  