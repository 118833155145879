import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

function round(v,digits){
  let r=Math.pow(10,digits)
  return Math.round(v*r)/r
}

function BLDCConsts() {
  const [kv, setKv]=React.useState(900)
  let kt = 60 / (2*Math.PI*kv)
  return (
    <div style={{background:'white',textAlign:'left', padding:'5em'}}>
      <h2>Константы BLDC двигателя</h2>
      <h3>Постоянная размера двигателя</h3>
      <p>
      <img className='math' src="img/Km.svg" alttext="Km"/> это постоянная размера двигателя.
      В единицах СИ постоянная размера двигателя выражается в ньютон-метрах на квадратный корень ватт <img className='math' src="img/KmSI.svg" alttext="N⋅m/W"/></p>
        <p><img className='math' src="img/Kmeq.svg" alttext=""/></p>
        <p>где</p>
        <p><img  src="img/tau.svg" alttext="tau"/>  это крутящий момент двигателя (единица СИ: ньютон-метр)</p>
        <p><img  src="img/P.svg" alttext="P"/> это резистивная потеря мощности (единица СИ: Ватт)</p>
        <p>Постоянная двигателя не зависит от намотки (при условии, что для проводов используется один и тот же проводящий материал);
        например, намотка двигателя на 6 витков двумя параллельными проводами вместо 12 витков однопроволочного провода удвоит постоянную скорости, <img className='math' src="img/Kv.svg" /> но <img className='math' src="img/Km.svg" alttext="Km"/> останется неизменной.</p>
        <p><img className='math' src="img/Km.svg" alttext="Km"/> может использоваться для выбора размера двигателя. 
            <img className='math' src="img/Kv.svg" /> может использоваться для выбора обмотки для выбора скорости.
            Поскольку крутящий момент <img className='math' src="img/tau.svg" alttext="tau"/> равен току , <img className='math' src="img/I.svg" alttext="I"/> умноженному 
            на <img className='math' src="img/Kt.svg" alttext="Kt"/> , то <img className='math' src="img/Km.svg" alttext="Km"/> становится</p>
        <p><img className='math' src="img/Kmeq2.svg" alttext=""/></p>
        <p>где</p>
        <p><img className='math' src="img/I.svg" alttext="I"/> это ток (единица СИ, ампер)</p>
        <p><img className='math' src="img/R.svg" alttext="R"/> - это сопротивление (единица СИ, Ом)</p>
        <p><img className='math' src="img/Kt.svg" alttext="Kt"/> это постоянная крутящего момента двигателя (единица СИ, ньютон-метр на ампер, Н·м/А).
            Если два двигателя с одинаковым <img className='math' src="img/Kv.svg" /> крутящим моментом работают в паре с жестко соединенными валами, 
            то <img className='math' src="img/Kv.svg" /> система остается той же при условии параллельного электрического подключения. <img className='math' src="img/Km.svg" alttext="Km"/> Мощность 
            комбинированной системы увеличилась на <img className='math' src="img/sqrt2.svg" alttext="sqrt2"/>, потому что и крутящий момент, и потери удваиваются. В качестве альтернативы 
система могла бы работать с тем же крутящим моментом, что и раньше, при этом крутящий момент и ток разделялись бы поровну между двумя 
двигателями, что вдвое снижает потери на сопротивление.</p>
        <h3>Постоянная скорости двигателя</h3>
        <p><img className='math' src="img/Kv.svg" /> это скорость двигателя, или обороты двигателя, постоянная, измеряемая в оборотах в минуту (об / мин) на вольт</p>
        <p><img className='math' src="img/Kveq.svg" alttext=""/></p>
        <p><img className='math' src="img/Kv.svg" /> Номинальная мощность бесщеточного двигателя - это отношение частоты вращения двигателя без нагрузки (измеряется в об / мин) 
        к пиковому (не среднеквадратичному) напряжению на проводах, подключенных к катушкам (обратная ЭДС). Например, ненагруженный двигатель
        с <img className='math' src="img/Kv.svg" /> = 5700 об / мин / В, питаемый напряжением 11,1 В, будет работать с номинальной частотой вращения 63 270 об / мин (= 5700 об / мин / В × 11,1 В).
        Двигатель может не достигать этой теоретической скорости из-за нелинейных механических потерь. С другой стороны, если двигатель приводится в действие как генератор, напряжение холостого 
        хода между клеммами идеально пропорционально оборотам в минуту и соответствует <img className='math' src="img/Kv.svg" /> двигателя / генератора.</p>
        <p>По закону Ленца работающий двигатель генерирует обратную ЭДС, пропорциональную скорости. Как только скорость вращения двигателя становится такой, что обратная ЭДС равна напряжению батареи (также называемому напряжением сети постоянного тока), двигатель достигает своей предельной скорости.</p>

<h3>Постоянная момента двигателя</h3>
<p><img className='math' src="img/Kt.svg" alttext="Kt"/> создаваемый крутящий момент делится на ток якоря. Это может быть рассчитано исходя из постоянной скорости двигателя 
<img className='math' src="img/Kv.svg" />.</p>
        <p>
        <img className='math' src="img/Kteq.svg" alttext=""/>
        </p>
        <p>
где 
</p>
        <p>
<img className='math' src="img/Ia.svg" alttext="Ia"/> - ток якоря машины (единица СИ: ампер). 
</p>
<p><img className='math' src="img/Kt.svg" alttext="Kt"/> в основном используется для расчета тока якоря при заданном требовании крутящего момента:</p>
<p><img className='math' src="img/Iaeq.svg" alttext=""/></p>
<p>Единицами измерения постоянного крутящего момента в системе СИ являются ньютон-метры на ампер (Н·м/А). Поскольку 1 Н·м = 1 Дж и 1 А = 1 С/с, 
    то 1 Н · м / А = 1 Дж · с / С = 1 В · с (те же единицы, что и постоянная скрорости).</p>
<p>Взаимосвязь между <img className='math' src="img/Kt.svg" alttext="Kt"/> и <img className='math' src="img/Kv.svg" /> не является интуитивно понятной, 
вплоть до того, что многие люди просто утверждают, что крутящий момент и <img className='math' src="img/Kv.svg" /> вообще не связаны. Аналогия с 
гипотетическим линейным двигателем может помочь убедить в том, что это правда. </p>
<p>Предположим, что линейный двигатель имеет <img className='math' src="img/Kv.svg" /> значение 2 (м/с)/ В, то есть линейный привод генерирует один 
вольт обратной ЭДС при перемещении (или приводе в действие)
 со скоростью 2 м / с. И наоборот, <img className='math' src="img/seq.svg" alttext=""/> (s - скорость линейного двигателя, V - напряжение). </p>
<p>Полезная мощность этого линейного двигателя - это <img className='math2' src="img/Peq.svg" alttext=""/> P являющаяся мощностью, V эффективное напряжение 
(приложенное напряжение минус напряжение обратной ЭДС) и I ток. Но, поскольку мощность также равна силе, умноженной на скорость, сила F линейного двигателя равна 
<img className='math' src="img/Feq.svg" alttext=""/> или <img className='math' src="img/Feq2.svg" alttext=""/>. Что показывает обратную зависимость между 
силой на единицу тока и <img className='math' src="img/Kv.svg" /> мощностью линейного двигателя.</p>
<p>Чтобы перевести эту модель на вращающийся двигатель, можно просто присвоить якорю двигателя произвольный диаметр, например, 2 м, и для простоты предположить, 
что вся сила приложена по внешнему периметру ротора, что дает 1 м рычага.</p>
<p>Теперь, предположив, что <img className='math' src="img/Kv.svg" /> (угловая скорость на единицу напряжения) двигателя составляет 3600 об / мин / В, ее можно 
перевести в "линейную", умножив на 2π м (периметр ротора) и разделив на 60, поскольку угловая скорость измеряется "в минуту". Это линейно <img className='math' src="img/Kveqex.svg" />.
Теперь, если на этот двигатель подается ток 2 А и предполагается, что обратная ЭДС равна ровно 2 В, он вращается со скоростью 7200 об / мин, механическая мощность 
составляет 4 Вт, а усилие на роторе составляет <img className='math' src="img/Peqex.svg" /> Н или 0,0053 Н. </p>
<p>Крутящий момент на валу составляет 0,0053 Н⋅ м при 2 А из-за выбранного радиуса ротора (ровно 1 м). Выбрав другой радиус, изменится линейная величина <img className='math' src="img/Kv.svg" />, но 
конечный результат крутящего момента не изменится. Чтобы проверить результат, используйте формулу <img className='math' src="img/Peq2.svg" />.</p>
<p>Итак, двигатель с <img className='math' src="img/Kveqex2.svg" /> будет генерировать 0,00265 Н⋅ м крутящего момента на ампер тока, независимо от его 
размера или других характеристик. Это именно то значение <img className='math' src="img/Kt.svg" alttext="Kt"/>, которое было оценено по приведенной ранее формуле.</p>
      <div>
      <table style={{width:'100%'}}>
<tbody>
        <tr><td style={{textAlign:'end',width:'50%'}}>
        <TextField type='number' label="Коэффициент Kv" sx={{ m: 1, width: '22ch' }} inputProps={{min: 50, step:100,}}
        InputProps={{endAdornment: <InputAdornment position="end">Rpm/V</InputAdornment>}} value={kv} onChange={(event) => {setKv(event.target.value);}}/>
        </td><td style={{textAlign:'left',paddingLeft:'1em'}}>Постоянная момента мотора Kt = <b>{(+kt).toPrecision(4)}</b> Н·м/А</td></tr>
        </tbody>
        </table>
    </div>
  </div>
  );
}
 
export default BLDCConsts;
  