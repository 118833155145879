import * as React from 'react';
import { useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

function MD(props) {
    let { markdown } = useParams();
    let propsmarkdown = props.markdown
    console.log(markdown)
    const [md, setMd] = useState('<p>loading md</p>')
    useEffect(() => {
        fetch(`/md/${propsmarkdown ? propsmarkdown : markdown}.md`)
            .then(data => { return data.text(); })
            .then(data => { setMd(data); })
    }, [markdown,propsmarkdown])
    return <Markdown remarkPlugins={[remarkGfm]}>{md}</Markdown>
}
 
export default MD;
  