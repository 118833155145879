import logo from './logo.svg';
import './App.css';
import * as React from 'react';
import Button from '@mui/material/Button';
// import { Route,Routes } from 'react-router-dom';
// import Power from './components/power'
// import Drive from './components/drive';
// import BLDCConsts from './components/bldcconsts';
import ScrollTop from './components/ScrollTop';
import Routes from './routes';


const Dummy = ()=>    
<div>
  <Button variant="contained">Hello world</Button>;
  <a
    className="App-link"
    href="https://reactjs.org"
    target="_blank"
    rel="noopener noreferrer"
  >
    Learn React
  </a>
  </div>

const Dummy2 = ()=>    
<div>
  <Button variant="contained">Hello world</Button>;
  <a
    className="App-link"
    href="https://reactjs.org"
    target="_blank"
    rel="noopener noreferrer"
  >
    Learn React2
  </a>
  </div>


function App() {
  return (
    <ScrollTop>
      <Routes/>
    </ScrollTop>
  );
}

export default App;
