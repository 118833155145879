// assets
import { LoginOutlined, ProfileOutlined } from '@ant-design/icons';

// icons
const icons = {
  LoginOutlined,
  ProfileOutlined
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
  id: 'pages',
  title: 'Статьи',
  type: 'group',
  children: [
    {
      id: 'lipo-page',
      title: 'Аккумуляторы',
      type: 'item',
      url: '/md/lipo',
      icon: icons.ChromeOutlined
    },
    {
      id: 'drivetrain-page',
      title: 'Шасси',
      type: 'item',
      url: '/md/drivetrain',
      icon: icons.ChromeOutlined
    },
    {
      id: 'spinners-page',
      title: 'Спиннер',
      type: 'item',
      url: '/md/spinners',
      icon: icons.ChromeOutlined
    },
    {
      id: 'selectbldc-page',
      title: 'Выбор мотора BLDC',
      type: 'item',
      url: '/md/bldcmotorselection',
      icon: icons.ChromeOutlined
    },
    {
      id: 'bldcparam-page',
      title: 'Параметры BLDC',
      type: 'item',
      url: '/bldcconsts',
      icon: icons.ChromeOutlined
    },
    {
      id: 'rules-page',
      title: 'Регламент',
      type: 'item',
      url: '/md/rules',
      icon: icons.ChromeOutlined
    },
    // {
    //   id: 'login1',
    //   title: 'Login',
    //   type: 'item',
    //   url: '/login',
    //   icon: icons.LoginOutlined,
    //   target: true
    // },
    // {
    //   id: 'register1',
    //   title: 'Register',
    //   type: 'item',
    //   url: '/register',
    //   icon: icons.ProfileOutlined,
    //   target: true
    // }
  ]
};

export default pages;
