import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';

function round(v,digits){
  let r=Math.pow(10,digits)
  return Math.round(v*r)/r
}

function Power() {
  const [ringsize, setRingsize]=React.useState(2.5)
  const [botmass, setBotmass]=React.useState(1.5)

  let ringdiagonal = Math.sqrt(ringsize*ringsize*2)
  let botforce = botmass*0.9*9.8 // mass * k * g
  let botmaxacc = Math.round(botforce / botmass*100)/100
  let botmaxspeedtime = Math.round(Math.sqrt(2*ringdiagonal / botmaxacc)*100)/100
  let botmaxspeed = Math.sqrt(2*ringdiagonal*botmaxacc)


  return (
    <div>
      <div style={{background:'white',maxWidth:'80em',margin:'auto'}}>
      <h2>Движение, абсолютный максимум</h2>
      <div style={{alignItems:'center'}}>
      <TextField type='number' id="outlined-required" label="Масса" sx={{ m: 1, width: '22ch' }} 
        InputProps={{endAdornment: <InputAdornment position="end">кг</InputAdornment>,}} value={botmass} onChange={(event) => {setBotmass(event.target.value);}}/>
      <TextField type='number' id="outlined-required" label="Размер ринга" sx={{ m: 1, width: '22ch' }} 
        InputProps={{endAdornment: <InputAdornment position="end">м</InputAdornment>,}} value={ringsize} onChange={(event) => {setRingsize(event.target.value);}}/>
      <Box sx={{ width: '100%' }}>
        <table style={{width:'100%'}}>
        <tbody>
          <tr><td style={{textAlign:'end'}} >Максимальная сила тяги</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(botforce,2)}</b> Н</td></tr>
          <tr><td style={{textAlign:'end'}} >Максимальное ускорение</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{botmaxacc}</b> с</td></tr>
          <tr><td style={{textAlign:'end',width:'50%'}} >Диагоняль ринга</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(ringdiagonal,2)}</b> м</td></tr>
          <tr><td style={{textAlign:'end'}} >Максимальная скорость</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(botmaxspeed*3600/1000,1)}</b> км/ч</td></tr>
          <tr><td style={{textAlign:'end'}} >Максимальная скорость</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{round(botmaxspeed,1)}</b> м/с</td></tr>
          <tr><td style={{textAlign:'end'}} >Минимальное время от угла до угла</td><td style={{textAlign:'left',paddingLeft:'1em'}}><b>{botmaxspeedtime}</b> с</td></tr>
          </tbody>
        </table>
      </Box>
    </div>
  </div>
  </div>
  );
}
 
export default Power;
  